const daTing = require('./teleprompteridx')
module.exports.teleprompter =`<!DOCTYPE html>
<html data-bs-theme="dark">
    <head>
        <title>Local-Teleprompter</title>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM" crossorigin="anonymous">
        <meta name="viewport" content="width=device-width, initial-scale=1">    
        <meta charset="utf-8">
        <style>
            #buttons{
                display: none;
            }
            #viewer{
                overflow: hidden;
                height: 90vh;
                display: none;
                align-items: center;
                justify-content: left;
                flex-wrap: nowrap;
            }
            .viewerSpan {
                font-family: monospace, monospace;
                font-size: 5em;
                white-space: pre;
                overscroll-behavior: contain;
            }
        </style>
    </head>
    <body>
        <div class="container align-items-center" id="main">
            <br/>
            <div class="container">
                <div class="row align-items-start">
                    <div class="col text-start">
                        <h2>Select Advance Methods</h2>
                    </div>
                    <div class="col">
                    </div>
                    <div class="col text-end">
                        <button type="button" id="darkMode" onclick="darkMode()" class="btn btn-dark">🌞 Light Mode</button>
                    </div>
                </div>
            </div> 
            <form class="container">
                <div class="row align-items-start">
                    <div class="col text-start">
                        <div class="form-check form-switch">
                            <input id="click" class="form-check-input" type="checkbox" checked>
                            <label class="form-check-label" for="flexSwitchCheckDefault">Left Click / Tap</label>
                        </div>
                        <div class="form-check form-switch">
                            <input id="scroll" class="form-check-input" type="checkbox" checked>
                            <label class="form-check-label" for="flexSwitchCheckChecked">Scroll</label>
                        </div>
                    </div>
                    <div class="col text-start">
                        <div class="form-check form-switch">
                            <input id="right" class="form-check-input" type="checkbox" checked>
                            <label class="form-check-label" for="flexSwitchCheckChecked">Arrow Keys</label>
                        </div>
                        <div class="form-check form-switch">
                            <input id="space" class="form-check-input" type="checkbox" checked>
                            <label class="form-check-label" for="flexSwitchCheckChecked">Space Bar</label>
                        </div>
                    </div>
                </div>
                <div class="row align-items-start">
                    <div class="col text-start">
                        <div class="form-check form-switch">
                            <input id="time" class="form-check-input" type="checkbox" checked>
                            <label class="form-check-label" for="flexSwitchCheckChecked">Time Based</label>
                            <label for="customRange3" class="form-label">Word Speed (25-250 WPM): <span id="exactTime">150</span></label>
                            <input type="range" class="form-range" min="0.5" max="5.0" step="0.1" id="timeRange" value="3.0" onchange="setExactTime()">
                        </div>
                    </div>
                </div>
                <div class="row align-items-start">
                    <br />
                    <div class="mb-3">
                        <label for="exampleFormControlTextarea1" class="form-label">Enter Script Here</label>
                        <textarea class="form-control" id="textarea1" rows="3" placeholder="Add Text"></textarea>
                    </div>
                    <button type="button" class="btn btn-secondary" alt="Strip SRT Formatting" onclick="stripTextOfNums()">Strip SRT Formatting</button>
                    <br />
                    <button type="button" class="btn btn-secondary" alt="Strip New Lines" onclick="stripTextOfNewLines()">Strip Line Breaks</button>
                    <br />
                    <button type="button" class="btn btn-secondary" id="start" alt="Start teleprompting" onclick="startPrompter()">Run Teleprompter</button>
                </div>
            </form>
        </div>
        <div id="hiddenText"></div>
        <div id="buttons" style="position:fixed;">
            <button type="button" class="btn btn-secondary" id="hider" alt="Start teleprompting" onclick="toggleMain()">Hide Teleprompter</button>
            <button type="button" class="btn btn-secondary" id="rewind" alt="Rewind Teleprompter" onclick="restart()">🔁 Restart </button>
            <button type="button" class="btn btn-secondary" id="pause" alt="Pause Teleprompter" onclick="pause()">▶️ - Play</button>
            <button type="button" class="btn btn-secondary" id="rewind" alt="Rewind Teleprompter" onclick="rewind()">Back 3 Words 🔙</button>
        </div>
        <div id="viewer">
            <span class="viewerSpan" id="word0"></span><span class="viewerSpan"> </span>
            <span class="viewerSpan" id="word1"></span><span class="viewerSpan"> </span>
            <span class="viewerSpan" id="word2"></span><span class="viewerSpan"> </span>
            <span class="viewerSpan" id="word3"></span><span class="viewerSpan"> </span>
            <span class="viewerSpan" id="word4"></span>
        </div>
        <div id="prompter"></div>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.js" crossorigin="anonymous"></script> 
        <script>${daTing.daScript}</script>
    </body>
</html>`