module.exports.daScript = 
`
const AVG_WPM = 150.0
let advClick = document.getElementById('click')
let advScroll = document.getElementById('scroll')
let advRight = document.getElementById('right')
let advSpace = document.getElementById('space')
let advTime = document.getElementById('time')
let isDarkMode = true
let underlineIdx = 1
let lastSpanIdx = 0
let textTraverser = null
let currWPM = 0

advTime.addEventListener('click', (event)=>{
    if(advTime.checked){
        document.getElementById('timeRange').disabled = false
    } else {
        document.getElementById('timeRange').disabled = true
    }
})

function darkMode(){
    if(isDarkMode){
        isDarkMode = !isDarkMode
        document.querySelector('html').setAttribute('data-bs-theme', 'light')
        document.getElementById('darkMode').innerHTML = '🌙 Dark Mode'
        document.getElementById('darkMode').classList.remove("btn-dark")
        document.getElementById('darkMode').classList.add("btn-light")
    } else {        
        isDarkMode = !isDarkMode
        document.querySelector('html').setAttribute('data-bs-theme', 'dark')
        document.getElementById('darkMode').innerHTML = '🌞 Light Mode'
        document.getElementById('darkMode').classList.remove("btn-light")
        document.getElementById('darkMode').classList.add("btn-dark")
    }
}

function setExactTime(){
    document.getElementById('exactTime').innerHTML = document.getElementById('timeRange').value * 50.0
}

function pause(){
    if(textTraverser != null){
        clearInterval(textTraverser)
        textTraverser = null
        document.getElementById('pause').innerHTML = '▶️ - Play'
    } else {
        textTraverser = setInterval(advanceUnderlining, 60000.0/currWPM)
        document.getElementById('pause').innerHTML = '⏸️ Pause'
    }
}

function formatToStart(){
    try{
        document.getElementById(\`a1\`).style.textDecoration = 'none'
        document.getElementById(\`a1\`).style.fontWeight = 'normal'
        document.getElementById(\`a0\`).style.textDecoration = 'underline'
        document.getElementById(\`a0\`).style.fontWeight = 'bold'
        document.getElementById(\`word1\`).style.textDecoration = 'none'
        document.getElementById(\`word1\`).style.fontWeight = 'normal'
        document.getElementById(\`word0\`).style.textDecoration = 'underline'
        document.getElementById(\`word0\`).style.fontWeight = 'bold'
        for(let i=1;i<lastSpanIdx-1;i++){
            document.getElementById(\`a\${i}\`).style.fontWeight = 'normal'
            document.getElementById(\`a\${i}\`).style.textDecoration = 'none'
        }
    } catch(e){console.log('formaterr', e)}
}

function rewind(){
    if(underlineIdx-4>=1){
        underlineIdx-=4
    } else {
        underlineIdx = 1
        formatToStart()
    }
    // DEBUG CLEAR FORMATTING OF WORDS PRIOR TO MOVING BACKWARDS
    document.getElementById('word0').innerHTML = (document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length>=4?
        // len >= 5, get only 4
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.slice(document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length-4,document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length):
        // or length 3?
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==3?" "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
        // or length 2? 
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==2?"  "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
        // or length 1?
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==1?"   "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
        // : or length 0
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==0?"    "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:""
        )
    document.getElementById('word1').innerHTML = document.getElementById(\`\${underlineIdx}\`) ? document.getElementById(\`\${underlineIdx}\`).innerHTML : " "
    document.getElementById('word2').innerHTML = document.getElementById(\`\${underlineIdx + 1}\`) ? document.getElementById(\`\${underlineIdx + 1}\`).innerHTML : " "
    document.getElementById('word3').innerHTML = document.getElementById(\`\${underlineIdx + 2}\`) ? document.getElementById(\`\${underlineIdx + 2}\`).innerHTML : " "
    document.getElementById('word4').innerHTML = document.getElementById(\`\${underlineIdx + 3}\`) ? document.getElementById(\`\${underlineIdx + 3}\`).innerHTML : " "
    document.getElementById(\`a\${underlineIdx + 1}\`).style.textDecoration = 'none'
    document.getElementById(\`a\${underlineIdx + 1}\`).style.fontWeight = 'normal'
    document.getElementById(\`a\${underlineIdx}\`).style.textDecoration = 'underline'
    document.getElementById(\`a\${underlineIdx}\`).style.fontWeight = 'bold'
}

function rewind1(){
    if(underlineIdx>1){
        underlineIdx-=1
    } else {
        formatToStart()
        return
    }
    document.getElementById('word0').innerHTML = (document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length>=4?
        // len >= 5, get only 4
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.slice(document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length-4,document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length):
        // or length 3?
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==3?" "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
        // or length 2? 
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==2?"  "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
        // or length 1?
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==1?"   "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
        // : or length 0
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==0?"    "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:""
        )
    document.getElementById('word1').innerHTML = document.getElementById(\`\${underlineIdx}\`) ? document.getElementById(\`\${underlineIdx}\`).innerHTML : " "
    document.getElementById('word2').innerHTML = document.getElementById(\`\${underlineIdx + 1}\`) ? document.getElementById(\`\${underlineIdx + 1}\`).innerHTML : " "
    document.getElementById('word3').innerHTML = document.getElementById(\`\${underlineIdx + 2}\`) ? document.getElementById(\`\${underlineIdx + 2}\`).innerHTML : " "
    document.getElementById('word4').innerHTML = document.getElementById(\`\${underlineIdx + 3}\`) ? document.getElementById(\`\${underlineIdx + 3}\`).innerHTML : " "
    if(document.getElementById(\`a\${underlineIdx + 1}\`)) {
        document.getElementById(\`a\${underlineIdx + 1}\`).style.textDecoration = 'none' 
        document.getElementById(\`a\${underlineIdx + 1}\`).style.fontWeight = 'normal' 
    } 
    if(document.getElementById(\`a\${underlineIdx}\`)){
        document.getElementById(\`a\${underlineIdx}\`).style.textDecoration = 'underline'
        document.getElementById(\`a\${underlineIdx}\`).style.fontWeight = 'bold'
    }
}

function restart(){
    underlineIdx = 1
    formatToStart()
    document.getElementById('word0').innerHTML = (document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length>=4?
        // len >= 5, get only 4
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.slice(document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length-4,document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length):
        // or length 3?
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==3?" "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
        // or length 2? 
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==2?"  "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
        // or length 1?
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==1?"   "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
        // : or length 0
        document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==0?"    "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:""
        )
    document.getElementById('word1').innerHTML = document.getElementById(\`\${underlineIdx}\`).innerHTML
    document.getElementById('word2').innerHTML = document.getElementById(\`\${underlineIdx + 1}\`).innerHTML
    document.getElementById('word3').innerHTML = document.getElementById(\`\${underlineIdx + 2}\`).innerHTML
    document.getElementById('word4').innerHTML = document.getElementById(\`\${underlineIdx + 3}\`).innerHTML
    document.getElementById(\`a\${underlineIdx + 1}\`).style.textDecoration = 'none'
    document.getElementById(\`a\${underlineIdx + 1}\`).style.fontWeight = 'normal'
    document.getElementById(\`a\${underlineIdx}\`).style.textDecoration = 'underline'
    document.getElementById(\`a\${underlineIdx}\`).style.fontWeight = 'bold'
}

function toggleMain(){
    if(document.getElementById('main').style.display == 'none'){
        document.getElementById('main').style.display = 'block'
        document.getElementById('prompter').style.display = 'none'
        document.getElementById('buttons').style.display = 'none'
        document.getElementById('viewer').style.display = 'none'
        clearInterval(textTraverser)
        restart()
    } else {
        document.getElementById('main').style.display = 'none'
        document.getElementById('viewer').style.display = 'flex'
        document.getElementById('prompter').style.display = 'block'
        document.getElementById('buttons').style.display = 'block'
        document.getElementById('word0').innerHTML = document.getElementById(0).innerHTML
        document.getElementById('word1').innerHTML = document.getElementById(1).innerHTML
        document.getElementById('word2').innerHTML = document.getElementById(2).innerHTML
        document.getElementById('word3').innerHTML = document.getElementById(3).innerHTML
        document.getElementById('word4').innerHTML = document.getElementById(4).innerHTML
    }
}

function showPrompter(){
    const scriptDoc = document.getElementById('textarea1').value.split('\\n').join('<p>')
    let lastIdxB = 0
    let lastIdxA = 0
    let wordIdentifier = scriptDoc.split(/[\\s\\n]+/).filter(str => str.length>0).map((word, idx) => {
        lastIdxB = idx
        return \`<span id="\${idx}">\${word}</span>\`
    })
    let wordIdentifier0 = scriptDoc.split(/[\\s\\n]+/).filter(str => str.length>0).map((word, idx) => {
        lastIdxA = idx
        return \`<span id="a\${idx}">\${word}</span>\`
    })
    wordIdentifier = [...wordIdentifier, \`<span id="\${lastIdxB+1}"> </span>\`]
    wordIdentifier0 = [...wordIdentifier0, \`<span id="a\${lastIdxA+1}"> </span>\`]
    console.log("Equals?", lastIdxA == lastIdxB)
    let textHidden = wordIdentifier.join("").split('<p>').filter(str => str.length>0).join(" ")
    let text = wordIdentifier0.join(" ")
    text = "<p>" + text + "</p>"
    document.getElementById('hiddenText').innerHTML = textHidden
    document.getElementById('hiddenText').style.display = 'none'
    document.getElementById('prompter').innerHTML = text
    document.getElementById('prompter').style.fontSize = '32px'
    toggleMain()
}

function startPrompter(){
    try {
        if(document.getElementById('textarea1').innerHTML.split(/[\s\\n]+/).filter(str => str.length>0).length < 5){
            document.getElementById('textarea1').innerHTML += "\\n Script entered is too short"
        }
        showPrompter()
        if(advClick.checked){
            advanceText(150)
            addEventListener("click", (event) => {
                advanceUnderlining()
            });
        }
        if(advScroll.checked){
            advanceText(150)
            addEventListener("scroll", (event) => {
                window.onscroll = function(e) {                
                    // print "false" if direction is down and "true" if up
                    if(this.oldScroll > this.scrollY){
                        rewind1()
                    } else {
                        advanceUnderlining()
                    }
                    this.oldScroll = this.scrollY;
                }
            });
        }
        if(advSpace.checked){
            advanceText(150)
            addEventListener("keydown", (event) => {
                event.preventDefault()
                if(event.key == ' '){
                    advanceUnderlining()
                }
            });
        }
        if(advRight.checked){
            advanceText(150)
            addEventListener("keydown", (event) => {
                event.preventDefault()
                if(event.key == 'ArrowRight' || event.key == 'ArrowDown'){
                    advanceUnderlining()
                } else if(event.key == 'ArrowLeft' || event.key == 'ArrowUp'){
                    rewind1()
                }
            });
        }
        if(advTime.checked){
            let speed = document.getElementById('timeRange').value
            advanceText(50.0 * speed)
        }
    } catch (err){console.log('startprompter err', err)}
}

function advanceUnderlining(){
    if(underlineIdx == 1){
        document.getElementById(\`a0\`).style.textDecoration = 'underline'
        document.getElementById(\`a0\`).style.fontWeight = 'bold'
        document.getElementById(\`a1\`).style.textDecoration = 'underline'
        document.getElementById(\`a1\`).style.fontWeight = 'bold'
        document.getElementById('word0').style.textDecoration = 'underline'
        document.getElementById('word0').style.fontWeight = 'bold'
        document.getElementById('word1').style.textDecoration = 'underline'
        document.getElementById('word1').style.fontWeight = 'bold'
        document.getElementById('word0').innerHTML = document.getElementById(\`\${underlineIdx-1}\`).innerHTML
        document.getElementById('word1').innerHTML = document.getElementById(\`\${underlineIdx}\`).innerHTML
        document.getElementById('word2').innerHTML = document.getElementById(\`\${underlineIdx + 1}\`).innerHTML
        document.getElementById('word3').innerHTML = document.getElementById(\`\${underlineIdx + 2}\`).innerHTML
        document.getElementById('word4').innerHTML = document.getElementById(\`\${underlineIdx + 3}\`).innerHTML
        underlineIdx++
    } else if (underlineIdx < lastSpanIdx-1){
        document.getElementById(\`a0\`).style.textDecoration = 'none'
        document.getElementById(\`a0\`).style.fontWeight = 'normal'
        document.getElementById(\`a\${underlineIdx - 1}\`).style.textDecoration = 'none'
        document.getElementById(\`a\${underlineIdx - 1}\`).style.fontWeight = 'normal'
        document.getElementById(\`a\${underlineIdx}\`).style.textDecoration = 'underline'
        document.getElementById(\`a\${underlineIdx}\`).style.fontWeight = 'bold'
        document.getElementById('word0').style.textDecoration = 'none'
        document.getElementById('word0').style.fontWeight = 'normal'
        document.getElementById('word1').style.textDecoration = 'underline'
        document.getElementById('word1').style.fontWeight = 'bold'
        document.getElementById('word0').innerHTML = (document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length>=4?
            // len >= 5, get only 4
            document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.slice(document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length-4,document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length):
            // or length 3?
            document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==3?" "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
            // or length 2? 
            document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==2?"  "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
            // or length 1?
            document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==1?"   "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:
            // : or length 0
            document.getElementById(\`\${underlineIdx - 1}\`).innerHTML.length==0?"    "+document.getElementById(\`\${underlineIdx - 1}\`).innerHTML:""
            )
        document.getElementById('word1').innerHTML = document.getElementById(\`\${underlineIdx}\`) ? document.getElementById(\`\${underlineIdx}\`).innerHTML : " "
        document.getElementById('word2').innerHTML = document.getElementById(\`\${underlineIdx + 1}\`) ? document.getElementById(\`\${underlineIdx + 1}\`).innerHTML : " "
        document.getElementById('word3').innerHTML = document.getElementById(\`\${underlineIdx + 2}\`) ? document.getElementById(\`\${underlineIdx + 2}\`).innerHTML : " "
        document.getElementById('word4').innerHTML = document.getElementById(\`\${underlineIdx + 3}\`) ? document.getElementById(\`\${underlineIdx + 3}\`).innerHTML : " "
        underlineIdx++
    }
}

function advanceText(wpm = 150.0){
    currWPM = wpm
    lastSpanIdx = document.getElementById('prompter').innerHTML.split('<span id="').length
    formatToStart()
}

function stripTextOfNums () {
    let theDocumentsText = document.getElementById('textarea1').value.split('\\n')
    let filteredDocText = theDocumentsText.filter(item => /.*[a-zA-Zа-яА-Я一-龥가-힣一-龠ぁ-ゔァ-ヴー].*/.test(item))
    document.getElementById('textarea1').value = filteredDocText.join('\\n')
}

function stripTextOfNewLines () {
    let theDocumentsText = document.getElementById('textarea1').value.split('\\n')
    document.getElementById('textarea1').value = theDocumentsText.join(' ')
}
`
